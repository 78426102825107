<template>
  <div class="container monitoring">
      <targets :is-loading="isLoading" :is-error="isError" :error-text="errorText"/>
<!--      <info-from-search-->
<!--          v-if="targetsFromSearch.length"-->
<!--      />-->
      <monitoring-filters
        :is-loading="isLoading"
      />

    <calendar/>
  </div>
</template>

<script>
import Targets from "@/components/monitoring/targets";
import InfoFromSearch from "@/components/monitoring/infoFromSearch";
import Calendar from "@/components/monitoring/calendar";
import MonitoringFilters from "@/components/monitoring/monitoringFilters";
import {mapGetters} from "vuex";
import AddTarget from "@/components/monitoring/addTarget";
export default {
  name: "Monitoring",
  components: {AddTarget, MonitoringFilters, Calendar, InfoFromSearch, Targets},
  data() {
    return {
      targetId: null,
      searchInfo: [],
      isLoading: false,
      isError: false,
      errorText: ""
    }
  },
  methods: {

    // createTarget(){
    //   this.$refs['addTarget'].showDialog();
    // },


    subscribe() {
      this.targetId && this.$store.dispatch('monitoring/subscribe', 5);
    },
    getTargets() {
      this.isLoading = true
      this.$store.dispatch('monitoring/getTargets').then(() => {
        this.isLoading = false
      }).catch(err => {
        this.isLoading = false;
        this.isError = true;
        this.errorText = err.response.data.message ? err.response.data.message : err.response.data.error
      })
    }
  },
  mounted() {
    if (!this.permissions.includes('monitoring.online_status')) {
      this.$router.push({name: 'Home'})
    }
    this.getTargets();
    this.$store.dispatch('monitoring/getSources', {
      order_type: 'desc',
    })

  },
  computed: {
    ...mapGetters('monitoring', ['targets','targetsFromSearch']),
    ...mapGetters('auth', ['permissions'])
  }
}
</script>

<style scoped>
  .container{
    margin-top: 1.5rem;
  }
</style>
<style>
.monitoring .card-content{
  padding: 20px;
}
@media (max-width: 991px) {
  .monitoring .card-header h4 {
    font-size: 15px;
  }
}
@media (min-width: 992px) {
  .monitoring .card-header h4 {
    font-size: 18px;
  }
}
.monitoring .card-header h4 {
  margin-bottom: 0;
}
</style>